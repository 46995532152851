// If you are using JavaScript/ECMAScript modules:
import Dropzone from "dropzone";

// If you are using an older version than Dropzone 6.0.0,
// then you need to disabled the autoDiscover behaviour here:
Dropzone.autoDiscover = false;

if (document.querySelector('.dropzone-carousel')) {
    let carouselDropzone = new Dropzone(".dropzone-carousel", {
        method: 'POST',
        url: '/initiatives?dropzone=1',
        headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]')?.content,
        },
        acceptedFiles: "image/png,image/jpg,image/jpeg,image/webp",
        maxFilesize: 2,
        init: function () {
            this.on("addedfile", function (file) {
                // Voeg een "Verwijder" knop toe voor elk bestand
                const btn = '<button class="position-absolute btn btn-sm btn-danger" style="top: -10px; right: -10px; z-index: 9999;" type="button"><i class="far fa-trash"></i></button>';
                var removeButton = Dropzone.createElement(btn);
    
                // Verwijder het bestand wanneer op de knop wordt geklikt
                removeButton.addEventListener("click", function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    carouselDropzone.removeFile(file);
                });
    
                // Voeg de knop toe aan het voorbeeld van het bestand
                file.previewElement.appendChild(removeButton);
            });
        }
    });
}

if (document.querySelector('.dropzone-image')) {
    let imageDropzone = new Dropzone(".dropzone-image", { method: 'POST',
        url: '/initiatives?dropzone=2',
        headers: {
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]')?.content,
        },
        acceptedFiles: "image/png,image/jpg,image/jpeg,image/webp",
        maxFilesize: 2,
        init: function () {
            this.on("addedfile", function (file) {
                // Voeg een "Verwijder" knop toe voor elk bestand
                const btn = '<button class="position-absolute btn btn-sm btn-danger" style="top: -10px; right: -10px; z-index: 9999;" type="button"><i class="far fa-trash"></i></button>';
                var removeButton = Dropzone.createElement(btn);
    
                // Verwijder het bestand wanneer op de knop wordt geklikt
                removeButton.addEventListener("click", function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    carouselDropzone.removeFile(file);
                });
    
                // Voeg de knop toe aan het voorbeeld van het bestand
                file.previewElement.appendChild(removeButton);
            });
        }
    });
}