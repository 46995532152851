function setSectionNumbers()
{
    const sections = document.querySelectorAll(['.initiative-section']);

    sections.forEach((section, index) => {
        const fields = section.querySelectorAll('[name^="content"]');
        fields.forEach((field) => {
            if (field.name.includes('--section--')) {
                field.name = field.name.replace('--section--', index);
            } else {
                const regEx = new RegExp('content\[[0-9]+\]');
                if (regEx.test(field.name)) {
                    field.name = field.name.replace(regEx, 'content[' + index + ']');
                }
            }
        });
    });
}

(() => {
    setSectionNumbers();
})();