require('./bootstrap');

require('./app/toggle-password');

require('./app/select-all');

require('./app/select-multiple');

require('./app/dropzone');

require('./app/initiative');

window.Swal = require('sweetalert2')

tinymce.init({
    selector: ".tiny-mce",
    plugins: 'code',
    menubar: false,
    toolbar: 'undo redo styleselect bold italic alignleft aligncenter alignright alignjustify | bullist numlist outdent indent code',
    statusbar: false,
    editor_encoding: "raw",
    entity_encoding: "raw",
    convert_urls : false,
    promotion: false,
    height : "400",
    setup: function (editor) {
        editor.on('change', function () {
            tinymce.triggerSave();
        });

        if ($('#' + editor.id).prop('readonly') || $('#' + editor.id).prop('disabled')) {
            editor.settings.readonly = true;
        }
    },
});

$(document).ready(function() {
    $('[data-bs-toggle="tooltip"]').tooltip();
});